import {
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
  Color,
  FileLoader,
  Vector2,
  Vector3,
  SpotLight,
  SpotLightHelper,
  AmbientLight,
  ReinhardToneMapping,
  sRGBEncoding,
  TextureLoader
} from "three";

// import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter.js";
import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js";
import Stats from "three/examples/jsm/libs/stats.module.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";

import { CopyShader } from "three/examples/jsm/shaders/CopyShader.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";

import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { TransparentBloomPass } from "./scripts/TransparentBloomPass.js";

import { CSS3DRenderer } from "three/examples/jsm/renderers/CSS3DRenderer.js";

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import gsap from "gsap";
import gsapCore from "gsap/gsap-core";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "./scripts/ScrollSmoother.js";


import { wormhole, wormholeSpline, galaxy2, updateWormholeOpacity } from "./components/wormhole";
import { createTubeGroup, tube, fadeInTube, fadeOutTube, maintainTube, hideTop, showTop } from "./components/tubes";
//import { onMouseMoveTween, setTweenParams } from "./components/tween"
import { panelGroup, panelElement, hideAllPanels, showAllPanels, fadeInPanel, maintainPanel, fadeOutPanel, getEndPoint } from "./components/panel"

let container;
let cssContainer;

let scene;
let stats;
let camera;
let renderer;
let cssRenderer;
let controls;
// let materials;
let standard;

const useEffects = true;
let composer1;
let composer2;
let bloomPass;

let backgroundStationarySpeed = 0.0000;
let backgroundScrollingSpeed = 0.0001;
let backgroundStationaryOpacity = 0.0;
let backgroundScrollingOpacity = 0.05;
let backgroundStationaryScrollingOpacity = backgroundScrollingOpacity;
let backgroundRepeatX = 1;
let backgroundRepeatY = 5;
let backgroundVisibility = true;
let backgroundTexture = null;
let backgroundMotion = true;
let backgroundOrbitControls = false;

let firstRender = true

let navSpeed = 0.8;

let tubesInterval;
let tubeRightSeparation = 0.5;
let tubeRightBend = 25;
let tubeRightCount = 135;
let tubeRightMaxRadius = 25;
let tubeRightThickness = 0.3;
let tubeLeftSeparation = 0.5;
let tubeLeftBend = 25;
let tubeLeftCount = 135;
let tubeLeftMaxRadius = 25;
let tubeLeftThickness = 0.3;
// Tube animation variables
let tubeGroupNumber = 67
let tubeStaticThreshold = 67
let tubeStep = {
  basis: 12,
  max: 2.4,
  min: 1.2
}
let tubeLength = {
  basis: 12 * 4 * 3, // The basis of all tube lengths
  max: 120,
  min: 50
}
let tubeDistanceApart = 1170;

let firstRunR = true
let firstRunL = true
let tubesR;
let tubesL;
let drawCountL = {
  start: new Array(tubeStaticThreshold).fill(0),
  count: new Array(tubeStaticThreshold).fill(0),
  length: new Array(tubeStaticThreshold).fill(0),
  step: new Array(tubeStaticThreshold).fill(0)
};
let drawCountR = {
  start: new Array(tubeStaticThreshold).fill(0),
  count: new Array(tubeStaticThreshold).fill(0),
  length: new Array(tubeStaticThreshold).fill(0),
  step: new Array(tubeStaticThreshold).fill(0)
};

let geometryParams = {
  separation: 0.5,
  bend: 25,
  count: 160,
  maxRadius: 35,
  thickness: 0.3
};

let starDuration = 5
let starDelay = 300

const starParams = {
  delay: 300,
  duration: 5
}

const scrollingParams = {
  scrollingSpeed: 0.8
}

const animationParams = {
  group: 200, // Change this to control the number of subgroups the tubesL and tubesR groups are divided into when controlling animation
  threshold: 150, // Change this to control how many tubes remain static, must be less than or equal to tubeGroupNumber, groups above the threshold will remain static
  stepSize: 12, // Change this to control how many vertices of the tube gets drawn at each render, must be a multiple of 3 (preferably 12)
  maxStep: 1.2,
  minStep: 0.4,
  maxLength: 35, // Max length of tube as multiple of tubeLength basis
  minLength: 10, // min length of tube as multiple of basis
  distance: 390 // Change this to control the distance between each tubeGroup, must be a multiple of 3, tubeStaticThreshold * this should not be greater than total position count of tube * 3 * 2
}

const bloomParams = {
  exposure: 1.05,
  bloomThreshold: 0.11,
  bloomStrength: 2.5,
  bloomRadius: 0.5

};

const ambientColorFormats = {
  string: "#ffffff",
  int: 0xffffff,
  object: { r: 1, g: 1, b: 1 },
  array: [1, 1, 1]
};

const frontSpotlightColorFormats = {
  string: "#505050",
  int: 0x505050,
  object: { r: 1, g: 1, b: 1 },
  array: [1, 1, 1]
};

const backSpotlightColorFormats = {
  string: "#d1d1d1",
  int: 0xd1d1d1,
  object: { r: 1, g: 1, b: 1 },
  array: [1, 1, 1]
};

const ambientLightDefs = [{ description: "Ambient light", type: "ambient", color: 0xffffff, intensity: 2.5 }];

const spotlightLeftDefs1 = [{
  description: "First left spotlight",
  type: "spot",
  color: 0x505050,
  intensity: 100,
  position: new Vector3(0, 0, -20),
  target: new Vector3(-90, 0, -400),
  distance: 300,
  angle: 0.9,
  penumbra: 1.5,
  r: 1,
  g: 1,
  b: 1
}]

const spotlightLeftDefs2 = [{
  description: "Second left spotlight",
  type: "spot",
  color: 0xd1d1d1,
  intensity: 125,
  position: new Vector3(0, 0, -50),
  target: new Vector3(-95, 0, -450),
  distance: 200,
  angle: 0.95,
  penumbra: 1.2,
  r: 1,
  g: 1,
  b: 1
}];

const spotlightRightDefs1 = [{
  description: "First right spotlight",
  type: "spot",
  color: 0x505050,
  intensity: 100,
  position: new Vector3(0, 0, -20),
  target: new Vector3(90, 0, -400),
  distance: 300,
  angle: 0.9,
  penumbra: 1.5,
  r: 1,
  g: 1,
  b: 1
}]

const spotlightRightDefs2 = [{
  description: "Second right spotlight",
  type: "spot",
  color: 0xd1d1d1,
  intensity: 125,
  position: new Vector3(0, 0, -50),
  target: new Vector3(95, 0, -450),
  distance: 200,
  angle: 0.95,
  penumbra: 1.2,
  r: 1,
  g: 1,
  b: 1
}];

// define light arrays
const ambientLights = [];
const spotlightsR1 = [];
const spotlightsR2 = [];
const spotlightsL1 = [];
const spotlightsL2 = [];
const showLightSpotlightHelpers = false;

const gui = new GUI();
init();
// gui.hide();
// function checkDevice (){
//   if(window.innerWidth >= 1024) {
//     console.log('desktop');
//   }else{
//     console.log('mobile');
//   }
// }
// window.addEventListener('resize', function(event){
//   init();
// function checkDevice() {
//   if (window.innerWidth >= 1024) {
//     console.log('desktop');
//   } else {
//     console.log('mobile');
//   }
// }
// window.addEventListener('resize', function (event) {
//   checkDevice();
// });
if (window.innerWidth > 768) window.addEventListener('resize', function () {
  // console.log('resize')
  location.reload()
})

function init() {
  container = document.getElementById("container");
  cssContainer = document.getElementById("css_container");
  scene = new Scene();

  //scene.fog = new FogExp2(0x000000, 0.009);

  var loader = new TextureLoader();
  backgroundTexture = loader.load(require("./textures/background/background-bottom-2.png"));
  scene.background = backgroundTexture;
  // scene.background = null

  createCamera();
  createLights();
  createRenderer();
  // createMaterials();
  createMeshes()
  createPostprocessing();
  createGUI();
  createControls()
  //need to be here to get the bloom properly
  renderer.toneMappingExposure = Math.pow(bloomParams.exposure, 4);

  renderer.setAnimationLoop(() => {
    if (backgroundOrbitControls) update()
    render();
  });
  window.scrollTo(0, 0);
}

/*                   _
                    | |
  _ __ ___ _ __   __| | ___ _ __ ___ _ __
 | '__/ _ \ '_ \ / _` |/ _ \ '__/ _ \ '__|
 | | |  __/ | | | (_| |  __/ | |  __/ |
 |_|  \___|_| |_|\__,_|\___|_|  \___|_|         */
function createRenderer() {
  renderer = new WebGLRenderer({
    antialias: window.devicePixelRatio > 1 ? false : true,
    powerPreference: "high-performance",
    alpha: true
  });
  cssRenderer = new CSS3DRenderer();

  renderer.setSize(container.clientWidth, container.clientHeight);
  renderer.setPixelRatio(window.devicePixelRatio > 1 ? window.devicePixelRatio * 0.85 : window.devicePixelRatio);

  cssRenderer.setSize(container.clientWidth, container.clientHeight);
  // cssRenderer.setPixelRatio(window.devicePixelRatio);

  renderer.outputEncoding = sRGBEncoding;
  renderer.toneMapping = ReinhardToneMapping;
  renderer.physicallyCorrectLights = true;
  renderer.toneMappingExposure = Math.pow(bloomParams.exposure, 4);

  container.appendChild(renderer.domElement);
  cssContainer.appendChild(cssRenderer.domElement);
}

/*_ _       _     _
 | (_)     | |   | |
 | |_  __ _| |__ | |_ ___
 | | |/ _` | '_ \| __/ __|
 | | | (_| | | | | |_\__ \
 |_|_|\__, |_| |_|\__|___/
       __/ |
      |___/
*/
function createLight(lightDef) {

  const tempLight = new SpotLight(lightDef.color, lightDef.intensity);
  /*
  tempLight.castShadow = true;
  tempLight.shadow.mapSize.x = 2048;
  tempLight.shadow.mapSize.y = 2048;
  tempLight.shadow.camera.near = 0.1;
*/

  tempLight.name = lightDef.description;
  tempLight.position.set(...lightDef.position);
  tempLight.angle = lightDef.angle;
  tempLight.distance = lightDef.distance;
  tempLight.penumbra = lightDef.penumbra;

  tempLight.target.position.set(...lightDef.target);
  camera.add(tempLight);
  camera.add(tempLight.target);
  scene.add(camera)

  tempLight.target.updateMatrixWorld();

  if (showLightSpotlightHelpers) {
    const spotLightHelper = new SpotLightHelper(tempLight);
    spotLightHelper.name = 'Helper : ' + lightDef.description
    scene.add(spotLightHelper);
  }

  return tempLight;
}

function createLights() {
  ambientLightDefs.forEach((lightDef) => {
    const ambientLight = new AmbientLight(lightDef.color, lightDef.intensity);
    scene.add(ambientLight);
    ambientLights.push(ambientLight);
  });

  spotlightRightDefs1.forEach((lightDef) => {
    spotlightsR1.push(createLight(lightDef))
  });
  spotlightRightDefs2.forEach((lightDef) => {
    spotlightsR2.push(createLight(lightDef))
  });


  spotlightLeftDefs1.forEach((lightDef) => {
    spotlightsL1.push(createLight(lightDef))
  });
  spotlightLeftDefs2.forEach((lightDef) => {
    spotlightsL2.push(createLight(lightDef))
  });
}

/*  ___ __ _ _ __ ___   ___ _ __ __ _
  / __/ _` | '_ ` _ \ / _ \ '__/ _` |
 | (_| (_| | | | | | |  __/ | | (_| |
  \___\__,_|_| |_| |_|\___|_|  \__,_/
*/
function createCamera() {
  camera = new PerspectiveCamera(
    60, // fov
    container.clientWidth / container.clientHeight, // aspect
    0.1, // near
    200 // far
  );
}

/*                _            _       _
                 | |          (_)     | |
  _ __ ___   __ _| |_ ___ _ __ _  __ _| |___
 | '_ ` _ \ / _` | __/ _ \ '__| |/ _` | / __|
 | | | | | | (_| | ||  __/ |  | | (_| | \__ \
 |_| |_| |_|\__,_|\__\___|_|  |_|\__,_|_|___/
*/
// function createMaterials() {


// const wireframe = new MeshBasicMaterial({
//   wireframe: true
// });

// let randColor = () => {
//   return "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
// }

// let phong = new MeshPhongMaterial({
//   color: "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase()//randColor()
// });

// materials = {
//   wireframe,
//   standard,
//   phong
// };

// createMeshes();

// return materials;
// }


/*                    _
                     | |
  _ __ ___   ___  ___| |__   ___  ___
 | '_ ` _ \ / _ \/ __| '_ \ / _ \/ __|
 | | | | | |  __/\__ \ | | |  __/\__ \
 |_| |_| |_|\___||___/_| |_|\___||___/
 */


function createMeshes() {

  drawCountL = {
    start: new Array(tubeStaticThreshold).fill(0),
    count: new Array(tubeStaticThreshold).fill(0),
    length: new Array(tubeStaticThreshold).fill(0),
    step: new Array(tubeStaticThreshold).fill(0)
  };
  drawCountR = {
    start: new Array(tubeStaticThreshold).fill(0),
    count: new Array(tubeStaticThreshold).fill(0),
    length: new Array(tubeStaticThreshold).fill(0),
    step: new Array(tubeStaticThreshold).fill(0)
  }
  firstRunL = true
  firstRunR = true

  // Testing wormhole
  scene.add(wormhole)

  // Testing tubes with wormhole
  scene.add(wormhole)
  tubesR = createTubeGroup('right', tubeRightSeparation, tubeRightBend, tubeRightCount, tubeRightMaxRadius, tubeRightThickness);
  scene.add(tubesR)
  tubesL = createTubeGroup('left', tubeLeftSeparation, tubeLeftBend, tubeLeftCount, tubeLeftMaxRadius, tubeLeftThickness); //, 0.5,20)
  scene.add(tubesL)

  tubesL.children.forEach(element => {
    element.material.opacity = 0;
  });
}


/*       __  __          _
       / _|/ _|        | |
   ___| |_| |_ ___  ___| |_ ___
  / _ \  _|  _/ _ \/ __| __/ __|
 |  __/ | | ||  __/ (__| |_\__ \
  \___|_| |_| \___|\___|\__|___/
*/
function createPostprocessing() {
  const renderScene = new RenderPass(scene, camera);
  // bloomPass = new UnrealBloomPass(
  //   new Vector2(window.innerWidth, window.innerHeight),
  //   1.5,
  //   0.4,
  //   0.6
  // );
  bloomPass = new TransparentBloomPass(
    new Vector2(window.innerWidth, window.innerHeight),
    2.5,
    0.8,
    0
  )

  bloomPass.renderToScreen = true;

  // transparentPass = new TransparentBloomPass(
  //   new Vector2(window.innerWidth, window.innerHeight),
  //   2,
  // 0.6,
  // 0.6
  // )
  // transparentPass.renderToScreen = true;

  const renderPass = new RenderPass(scene, camera);

  const fxaaPass = new ShaderPass(FXAAShader);
  const copyPass = new ShaderPass(CopyShader);

  composer1 = new EffectComposer(renderer);
  composer1.addPass(renderPass);
  composer1.addPass(copyPass);

  const pixelRatio = renderer.getPixelRatio();

  fxaaPass.material.uniforms["resolution"].value.x =
    1 / (container.offsetWidth * pixelRatio);
  fxaaPass.material.uniforms["resolution"].value.y =
    1 / (container.offsetHeight * pixelRatio);

  composer2 = new EffectComposer(renderer);
  composer2.addPass(renderPass);
  composer2.addPass(bloomPass);
  composer2.addPass(fxaaPass);
  bloomPass.strength = Number(bloomParams.bloomStrength);
}

/*                _             _
                 | |           | |
   ___ ___  _ __ | |_ _ __ ___ | |___
  / __/ _ \| '_ \| __| '__/ _ \| / __|
 | (_| (_) | | | | |_| | | (_) | \__ \
  \___\___/|_| |_|\__|_|  \___/|_|___/    */

function createControls() {
  controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.screenSpacePanning = true;
  controls.keys = {
    LEFT: 'KeyA', //left arrow
    UP: 'KeyW', // up arrow
    RIGHT: 'KeyD', // right arrow
    BOTTOM: 'KeyS' // down arrow
  }
}

function loadFile(url) {
  const loader = new FileLoader();

  return new Promise((resolve) => {
    loader.load(url, (result) => {
      resolve(result);
    });
  });
}

function update() {
  controls.update();
}
/*                   _
                    | |
  _ __ ___ _ __   __| | ___ _ __
 | '__/ _ \ '_ \ / _` |/ _ \ '__|
 | | |  __/ | | | (_| |  __/ |
 |_|  \___|_| |_|\__,_|\___|_|   */
function render() {
  if (useEffects) {
    composer2.render();
  } else {
    renderer.render(scene, camera);
  }
  cssRenderer.render(scene, camera);
  stats?.update();

  //I like this (galaxy2.offset.x +=) on with a really low value. Adds subtle movement to the scene when stationary
  galaxy2.offset.x += backgroundStationarySpeed;
  //scene.background.offset.x += 0.005;
  //galaxy2.needsUpdate = true;
  if (firstRender) {
    firstRender = false
    gsap.to(window, { duration: 2.5, scrollTo: 0.76 * window.innerHeight, ease: 'none' });
  }
  if (backgroundMotion) {
    if (backgroundMotion) {
      animateTubes()
    }
  }
}

function animateTubes() {

  if (tubesR.children && tubesR.children.length > 0) {
    tubesR.children.forEach((el, i) => {
      if (i % tubeGroupNumber < tubeStaticThreshold) {
        if (drawCountR.start[i % tubeGroupNumber] - drawCountR.step[i % tubeGroupNumber] < 0 && drawCountR.count[i % tubeGroupNumber] - drawCountR.step[i % tubeGroupNumber] >= 0) {
          drawCountR.start[i % tubeGroupNumber] = 0
          drawCountR.count[i % tubeGroupNumber] = drawCountR.count[i % tubeGroupNumber] - drawCountR.step[i % tubeGroupNumber]
        } else if (drawCountR.start[i % tubeGroupNumber] <= 0) {
          drawCountR.start[i % tubeGroupNumber] = firstRunR ? el.geometry.attributes.position.count * 3 * 2 - getRandomStart('right') * tubeDistanceApart : el.geometry.attributes.position.count * 3 * 2
          // drawCountR.length[i % tubeGroupNumber] = i % 2 === 0 ? Math.round(Math.random() * (tubeLength.max - (tubeLength.max + tubeLength.min) / 2 + 1) + (tubeLength.max + tubeLength.min) / 2) * tubeLength.basis : Math.round(Math.random() * ((tubeLength.max + tubeLength.min) / 2 - tubeLength.min + 1) + tubeLength.min) * tubeLength.basis
          drawCountR.length[i % tubeGroupNumber] = i % 2 === 0 ? tubeLength.max * tubeLength.basis : tubeLength.min * tubeLength.basis
          drawCountR.step[i % tubeGroupNumber] = Math.round(Math.random() * (tubeStep.max - tubeStep.min + 1) + tubeStep.min) * tubeStep.basis
          // drawCountR.count[i % tubeGroupNumber] = drawCountR.step[i % tubeGroupNumber]
          drawCountR.count[i % tubeGroupNumber] = firstRunR ? drawCountR.length[i % tubeGroupNumber] : drawCountR.step[i % tubeGroupNumber]
        } else if (drawCountR.count[i % tubeGroupNumber] < drawCountR.length[i % tubeGroupNumber]) {
          drawCountR.start[i % tubeGroupNumber] = drawCountR.start[i % tubeGroupNumber] - drawCountR.step[i % tubeGroupNumber]
          drawCountR.count[i % tubeGroupNumber] += drawCountR.step[i % tubeGroupNumber]
        } else {
          drawCountR.start[i % tubeGroupNumber] = drawCountR.start[i % tubeGroupNumber] - drawCountR.step[i % tubeGroupNumber]
        }
        // if (i % tubeGroupNumber === 0) console.log(i % tubeGroupNumber, drawCountR.start[i % tubeGroupNumber], drawCountR.count[i % tubeGroupNumber], drawCountR.length[i % tubeGroupNumber])
        el.geometry.setDrawRange(drawCountR.start[i % tubeGroupNumber], drawCountR.count[i % tubeGroupNumber])
      }
      if (i === tubesR.children.length - 1 && firstRunR) firstRunR = false
    })
  }
  if (tubesL.children && tubesL.children.length > 0) {
    tubesL.children.forEach((el, i) => {
      if (i % tubeGroupNumber < tubeStaticThreshold) {
        if (drawCountL.start[i % tubeGroupNumber] - drawCountL.step[i % tubeGroupNumber] < 0 && drawCountL.count[i % tubeGroupNumber] - drawCountL.step[i % tubeGroupNumber] >= 0) {
          drawCountL.start[i % tubeGroupNumber] = 0
          drawCountL.count[i % tubeGroupNumber] = drawCountL.count[i % tubeGroupNumber] - drawCountL.step[i % tubeGroupNumber]
        } else if (drawCountL.count[i % tubeGroupNumber] <= 0) {
          // console.log(el.geometry.attributes.position.count * 3 * 2)
          drawCountL.start[i % tubeGroupNumber] = firstRunL ? el.geometry.attributes.position.count * 3 * 2 - getRandomStart('left') * tubeDistanceApart : el.geometry.attributes.position.count * 3 * 2
          // drawCountL.length[i % tubeGroupNumber] = i % 2 === 0 ? Math.round(Math.random() * (tubeLength.max - (tubeLength.max + tubeLength.min) / 2 + 1) + (tubeLength.max + tubeLength.min) / 2) * tubeLength.basis : Math.round(Math.random() * ((tubeLength.max + tubeLength.min) / 2 - tubeLength.min + 1) + tubeLength.min) * tubeLength.basis
          drawCountL.length[i % tubeGroupNumber] = i % 2 === 0 ? tubeLength.max * tubeLength.basis : tubeLength.min * tubeLength.basis
          drawCountL.step[i % tubeGroupNumber] = Math.round(Math.random() * (tubeStep.max - tubeStep.min + 1) + tubeStep.min) * tubeStep.basis
          // drawCountL.count[i % tubeGroupNumber] = drawCountL.step[i % tubeGroupNumber]
          drawCountL.count[i % tubeGroupNumber] = firstRunL ? drawCountL.length[i % tubeGroupNumber] : drawCountL.step[i % tubeGroupNumber]
          // console.log(i, i % tubeGroupNumber, drawCountL.length[i % tubeGroupNumber])
        } else if (drawCountL.count[i % tubeGroupNumber] < drawCountL.length[i % tubeGroupNumber]) {
          drawCountL.start[i % tubeGroupNumber] = drawCountL.start[i % tubeGroupNumber] - drawCountL.step[i % tubeGroupNumber]
          drawCountL.count[i % tubeGroupNumber] = drawCountL.count[i % tubeGroupNumber] + drawCountL.step[i % tubeGroupNumber]
        } else {
          drawCountL.start[i % tubeGroupNumber] = drawCountL.start[i % tubeGroupNumber] - drawCountL.step[i % tubeGroupNumber]
        }
        el.geometry.setDrawRange(drawCountL.start[i % tubeGroupNumber], drawCountL.count[i % tubeGroupNumber])
      }
      if (i === tubesL.children.length - 1 && firstRunL) firstRunL = false
    })
  }
}

let groupL = [...Array(tubeGroupNumber).keys()]
let groupR = [...Array(tubeGroupNumber).keys()]
function getRandomStart(side) {
  let number = Math.round(Math.random() * (tubeGroupNumber + 1))
  if (side === 'left') {
    if (groupL.includes(number)) {
      groupL.splice(groupL.indexOf(number), 1)
      if (groupL.length === 0) groupL = [...Array(tubeGroupNumber).keys()]
      return number
    } else return getRandomStart(side)
  } else {
    if (groupR.includes(number)) {
      groupR.splice(groupR.indexOf(number), 1)
      if (groupR.length === 0) groupR = [...Array(tubeGroupNumber).keys()]
      return number
    } else return getRandomStart(side)
  }
}

function onWindowResize() {
  const width = container.clientWidth;
  const height = container.clientHeight;

  camera.aspect = width / height;

  // Update camera frustum
  camera.updateProjectionMatrix();

  composer2.setSize(width, height);
  renderer.setSize(width, height);

  if (width < 768) {
    // centre div
    const panels = document.getElementsByClassName("iframe");
    const windowHeight = window.innerHeight;
    for (let panel of panels) {
      let panelDoc = panel.contentDocument || panel.contentWindow.document;
      let modal = panelDoc.getElementsByClassName("modal")[0]
      modal.style.top = windowHeight / 2 + "px";
    }
  }
}

window.addEventListener("resize", onWindowResize, false);

/*            _
             (_)
   __ _ _   _ _
  / _` | | | | |
 | (_| | |_| | |
  \__, |\__,_|_|
   __/ |
  |___/
  */
// Create color pickers for multiple color formats

function remakeMeshes() {
  scene.remove(tubesL)
  scene.remove(tubesR)
  createMeshes();
}

function createGUI() {
  const geometryFolder = gui.addFolder("geometry", false);
  geometryFolder
    .add(geometryParams, "separation", 0.1, 5)
    .step(0.1)
    .onChange((value) => {
      tubeLeftSeparation = value;
      tubeRightSeparation = value;
      remakeMeshes();
    });
  geometryFolder
    .add(geometryParams, "count", 1, 300)
    .step(1)
    .onChange((value) => {
      tubeLeftCount = value;
      tubeRightCount = value;
      remakeMeshes();
    });
  geometryFolder
    .add(geometryParams, "thickness", 0.1, 0.3)
    .step(0.005)
    .onChange((value) => {
      tubeLeftThickness = value;
      tubeRightThickness = value;
      remakeMeshes();
    });

  geometryFolder.close();

  const animationFolder = gui.addFolder("animation", false);
  animationFolder
    .add(animationParams, "group", 5, 200)
    .step(2)
    .onChange((value) => {
      tubeGroupNumber = value
      if (animationParams.threshold > value) {
        animationParams.threshold = value
      }
      remakeMeshes()
    })
  animationFolder
    .add(animationParams, "threshold", 1, 200)
    .step(2)
    .onChange((value) => {
      tubeStaticThreshold = value
      remakeMeshes()
    }).listen()
  animationFolder
    .add(animationParams, "maxStep", 0.0, 12)
    .step(0.1)
    .onChange((value) => {
      tubeStep.max = value
      remakeMeshes()
    })
  animationFolder
    .add(animationParams, "minStep", 0.0, 6)
    .step(0.1)
    .onChange((value) => {
      tubeStep.min = value
      remakeMeshes()
    })
  animationFolder
    .add(animationParams, "maxLength", 15, 45)
    .step(1)
    .onChange((value) => {
      tubeLength.max = value
      if (tubeLength.min > tubeLength.max) {
        animationParams.minLength = value
      }
      remakeMeshes()
    })
  animationFolder
    .add(animationParams, "minLength", 1, 45)
    .step(1)
    .onChange((value) => {
      tubeLength.min = value
      remakeMeshes()
    }).listen()
  animationFolder
    .add(animationParams, "distance", 1200, 4200)
    .step(30)
    .onChange((value) => {
      tubeDistanceApart = value
      remakeMeshes()
    })
  animationFolder.close()


  const bloomParamsFolder = gui.addFolder("effects", false);
  bloomParamsFolder
    .add(bloomParams, "exposure", 0.1, 2)
    .onChange(function (value) {
      renderer.toneMappingExposure = Math.pow(value, 4.0);
    });
  bloomParamsFolder
    .add(bloomParams, "bloomThreshold", 0.0, 2.0)
    .onChange(function (value) {
      bloomPass.threshold = Number(value);
    });
  bloomParamsFolder
    // .add(bloomParams, "bloomStrength", 0.0, 3.0)
    .add(bloomParams, "bloomStrength", 0.0, 4.0)
    .onChange(function (value) {
      bloomPass.strength = Number(value);
    });
  bloomParamsFolder
    .add(bloomParams, "bloomRadius", 0.0, 1.0)
    .step(0.01)
    .onChange(function (value) {
      bloomPass.radius = Number(value);
    });
  //renderer.toneMappingExposure = 1.3 //Math.pow(bloomParams.exposure, 4.0)
  //bloomPass.strength = Number(bloomParams.bloomStrength);
  //bloomPass.bloomRadius = Number(bloomPass.bloomRadius);
  bloomPass.threshold = Number(bloomParams.bloomThreshold);
  bloomParamsFolder.close();

  const lightFolder = gui.addFolder("lights", false);
  createAmbientLightFolder(ambientLightDefs[0], ambientLights, lightFolder, ambientColorFormats);
  createLightFolder(spotlightRightDefs1[0], spotlightsR1, lightFolder, frontSpotlightColorFormats);
  createLightFolder(spotlightRightDefs2[0], spotlightsR2, lightFolder, backSpotlightColorFormats);
  createLightFolder(spotlightLeftDefs1[0], spotlightsL1, lightFolder, frontSpotlightColorFormats);
  createLightFolder(spotlightLeftDefs2[0], spotlightsL2, lightFolder, backSpotlightColorFormats);
  lightFolder.close();

  let backgroundParams = {
    stationary: 0.0,
    scrolling: 0.00001,
    stationaryOpacity: 0.0,
    scrollingOpacity: 0.05,
    repeatX: 1,
    repeatY: 5,
    visible: true,
    motion: true,
    orbitControls: false
  };

  const backgroundFolder = gui.addFolder("background", false);
  backgroundFolder
    .add(backgroundParams, "stationary", -0.1, 0.1)
    .step(0.0005)
    .onChange(function (value) {
      backgroundStationarySpeed = value;
    });
  backgroundFolder
    .add(backgroundParams, "scrolling", -0.1, 0.1)
    .step(0.0005)
    .onChange(function (value) {
      backgroundScrollingSpeed = value;
    });
  backgroundFolder
    .add(backgroundParams, "stationaryOpacity", 0, 1.0)
    .step(0.05)
    .onChange(function (value) {
      backgroundStationaryOpacity = value;
      updateWormholeOpacity(backgroundStationaryOpacity);
    });
  backgroundFolder
    .add(backgroundParams, "scrollingOpacity", 0, 1.0)
    .step(0.05)
    .onChange(function (value) {
      backgroundScrollingOpacity = value;
      updateWormholeOpacity(backgroundScrollingOpacity);
    });
  backgroundFolder
    .add(backgroundParams, "repeatX", 0, 100)
    .step(1)
    .onChange(function (value) {
      galaxy2.repeat.set(value, backgroundRepeatY);
      backgroundRepeatX = value;
    });
  backgroundFolder
    .add(backgroundParams, "repeatY", 0, 100)
    .step(1)
    .onChange(function (value) {
      galaxy2.repeat.set(backgroundRepeatX, value);
      backgroundRepeatY = value;
    });
  backgroundFolder.close();

  const scrollFolder = gui.addFolder('scroll', false)
  scrollFolder.add(scrollingParams, "scrollingSpeed", 0, 4.0).step(0.1).onChange(function (value) {
    navSpeed = value
  })
  scrollFolder.close()

  const buttonSaveScreenshot = {
    saveScreenshot() {
      //var renderer1 = new WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true });
      //renderer.domElement.toDataURL("image/png");
      var w = window.open('', '');
      w.document.title = "Titan Capital Toolkit";
      //w.document.body.style.backgroundColor = "red";
      var img = new Image(window.innerWidth, window.innerHeight);
      // Without 'preserveDrawingBuffer' set to true, we must render now
      // var renderer1 = new WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true });
      //renderer1.clearDepth();
      //composer2.render();

      //renderer1.render(scene, camera);
      //img.src = renderer1.domElement.toDataURL();
      //createPostprocessing()
      // composer2.renderer.alpha = true
      // composer2.renderer.setSize(container.clientWidth, container.clientHeight);
      // composer2.renderer.setPixelRatio(4);
      // composer2.renderer.outputEncoding = sRGBEncoding;
      // composer2.renderer.toneMapping = ReinhardToneMapping;
      // composer2.renderer.physicallyCorrectLights = true;
      // composer2.renderer.toneMappingExposure = Math.pow(bloomParams.exposure, 4);
      scene.background = null
      composer2.renderer.setClearColor(0x000000, 0);
      composer2.render();
      console.log(composer2);
      img.src = composer2.renderer.domElement.toDataURL("image/png");

      w.document.body.appendChild(img);
      w.document.body.appendChild(img);
      scene.background = backgroundTexture
    }
  };

  // const buttonSaveGLB = {
  //   saveGLB() {
  //     const exporter = new GLTFExporter();

  //     // Parse the input and generate the glTF output
  //     exporter.parse(
  //       scene,
  //       // called when the gltf has been generated
  //       function (gltf) {

  //         console.log(gltf);
  //         //downloadJSON( gltf );
  //         saveArrayBuffer(gltf, 'titan-capital.glb');

  //       },
  //       // called when there is an error in the generation
  //       function (error) {

  //         console.log('An error happened');

  //       },
  //       { binary: true }
  //     );
  //   }
  // };

  // function saveArrayBuffer(buffer, filename) {

  //   save(new Blob([buffer], { type: 'application/octet-stream' }), filename);

  // }

  // const link = document.createElement('a');
  // link.style.display = 'none';
  // document.body.appendChild(link); // Firefox workaround, see #6594

  // function save(blob, filename) {

  //   link.href = URL.createObjectURL(blob);
  //   link.download = filename;
  //   link.click();

  //   // URL.revokeObjectURL( url ); breaks Firefox...

  // }

  const toolkitFolder = gui.addFolder("toolkit", false);
  toolkitFolder
    .add(backgroundParams, "visible")
    .onChange(function (value) {
      wormhole.visible = value;
      if (value) {
        showToolKit()
      } else {
        hideAllPanels()
        hideToolKit()
      }
      backgroundVisibility = value;
    });
  toolkitFolder
    .add(backgroundParams, "motion")
    .onChange(function (value) {
      backgroundMotion = value;
    });
  toolkitFolder
    .add(backgroundParams, "orbitControls")
    .onChange(function (value) {
      backgroundOrbitControls = value;
      changeControls()
    });
  toolkitFolder
    .add(buttonSaveScreenshot, "saveScreenshot");
  // toolkitFolder
  //   .add(buttonSaveGLB, "saveGLB");

  toolkitFolder.close()

  const starFolder = gui.addFolder("stars", false);
  starFolder
    .add(starParams, "duration", 5, 30)
    .step(1)
    .onChange(function (value) {
      starDuration = value
    });
  starFolder
    .add(starParams, "delay", 300, 1500)
    .step(50)
    .onChange(function (value) {
      starDelay = value
    });
  starFolder.close()

  const obj = {
    savePreset() {
      const preset = gui.save();
      console.log(preset);
    }
  };
  gui.close();
  gui.add(obj, "savePreset");

  stats = new Stats();
  // hide stats
  // container.appendChild(stats.dom);
}

function createAmbientLightFolder(def, lights, parentFolder, colorFormats) {
  let i = 0;
  const folder = parentFolder.addFolder(`${def.description}`);
  i++;

  folder.addColor(colorFormats, 'string')
    .onChange(function (value) {
      lights.forEach((light) => {
        light.color = new Color(value);
      });
    });
  folder
    .add(def, "intensity", -150, 150.0)
    .step(0.1)
    .onChange(function (value) {
      lights.forEach((light) => {
        light.intensity = value;
      });
    });
  folder.close();
}

function createLightFolder(def, lights, parentFolder, colorFormats) {
  let i = 0;
  const folder = parentFolder.addFolder(`${def.description}`);
  i++;

  folder.addColor(colorFormats, 'string')
    .onChange(function (value) {
      lights.forEach((light) => {
        light.color = new Color(value);
      });
    });
  folder
    .add(def, "intensity", 0.0, 5000.0)
    .step(10)
    .onChange(function (value) {
      lights.forEach((light) => {
        light.intensity = value;
      });
    });
  folder
    .add(def, "angle", 0.0, Math.PI / 2)
    .step(Math.PI / 64)
    .onChange(function (value) {
      lights.forEach((light) => {
        light.angle = value;
      });
    });
  folder
    .add(def, "distance", 0.0, 1000)
    .step(10)
    .onChange(function (value) {
      lights.forEach((light) => {
        light.distance = value;
      });
    });
  folder
    .add(def, "penumbra", 0.0, 2)
    .step(0.1)
    .onChange(function (value) {
      lights.forEach((light) => {
        light.penumbra = value;
      });
    });
  folder.close();
}


/*

  _     _             _                          _
 | |   | |           | |                        | |
 | |__ | |_ _ __ ___ | |  _ __   __ _ _ __   ___| |
 | '_ \| __| '_ ` _ \| | | '_ \ / _` | '_ \ / _ \ |
 | | | | |_| | | | | | | | |_) | (_| | | | |  __/ |
 |_| |_|\__|_| |_| |_|_| | .__/ \__,_|_| |_|\___|_|
                         | |
                         |_|
*/


//0 : Start
panelGroup.add(new panelElement("1", 'left'));
//1-4 : Loans 1-4
panelGroup.add(new panelElement("2", 'left'));
panelGroup.add(new panelElement("3", 'right'));
panelGroup.add(new panelElement("4", 'right'));
panelGroup.add(new panelElement("5", 'right'));
//5 : About
panelGroup.add(new panelElement("6", 'left'));
//6 : Tea
panelGroup.add(new panelElement("7", 'left'));
//7 : Case Studies
panelGroup.add(new panelElement("8", 'right'));
//8 : Testimonials
panelGroup.add(new panelElement("9", 'right'));
//9 : Contact
panelGroup.add(new panelElement("10", 'center'));
//10 : Footer
panelGroup.add(new panelElement("11", 'center'));
scene.add(panelGroup);

hideAllPanels();

/*

   _____                _ _   _             _
  / ____|              | | | | |           (_)
 | (___   ___ _ __ ___ | | | | | ___   __ _ _  ___
  \___ \ / __| '__/ _ \| | | | |/ _ \ / _` | |/ __|
  ____) | (__| | | (_) | | | | | (_) | (_| | | (__
 |_____/ \___|_|  \___/|_|_| |_|\___/ \__, |_|\___|
                                       __/ |
                                      |___/
*/
gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);

let scrollParams = {
  percentDirect: 0,
  percentSmooth: 0
};

let lastScrollPos = 0;
let scrollDelta = 0;
let pathPosition = 0

let navigationItem = document.querySelectorAll('.navigation .item');
let scrollPercentageMultiplier = 1500 / 17;
navigationItem.forEach((el) => el.addEventListener('click', function () {
  // window.scrollTo(window.scrollX, el.dataset.index * scrollPercentageMultiplier);
  // window.scrollTo(window.scrollX, el.getAttribute('data-index') * window.innerHeight);
  const navigations = Array.from(navigationItem)
  const currNav = navigations.find(x => x.classList.contains("active"))
  const dataIndices = ['0.76', '1.9', '8.17', '10.73', '15.25']
  const panelCount = [1, 4, 2, 2]
  const end = dataIndices.indexOf(el.getAttribute('data-index'))
  const start = dataIndices.indexOf(currNav.getAttribute('data-index'))
  let step = 0
  if (start > end) {
    for (let i = start - 1; i >= end; i--) {
      step += panelCount[i]
    }
  } else {
    for (let i = start; i < end; i++) {
      step += panelCount[i]
    }
  }
  // console.log(step)
  // const step = Math.abs(dataIndices.indexOf(el.getAttribute('data-index')) - dataIndices.indexOf(currNav.getAttribute('data-index')))
  // console.log(step, dataIndices.indexOf(el.getAttribute('data-index')), dataIndices.indexOf(currNav.getAttribute('data-index')))
  gsap.to(window, { duration: navSpeed * step, scrollTo: el.getAttribute('data-index') * window.innerHeight, ease: 'none' });

}))


// greensock scroll
let smoother = ScrollSmoother.create({
  smooth: 2,
  effects: true,
  ease: 'power4.out',
  onUpdate: self => {
    // console.log('test');
    // console.log("progress", self.ratio)
    scrollAction(self.ratio);
  },
  onStop: self => {
    // backgroundStationaryScrollingOpacity = backgroundScrollingOpacity
    // let backgroundOpacityInterval = setInterval(() => {
    //   backgroundStationaryScrollingOpacity -= 0.002
    //   updateWormholeOpacity(backgroundStationaryScrollingOpacity);
    //   if (backgroundStationaryScrollingOpacity < 0) {
    //     clearInterval(backgroundOpacityInterval);
    //   }
    // }, 10);
    //scrollLock(self)
  }
});

let timer = null;
window.addEventListener('scroll', function () {
  if (backgroundStationaryScrollingOpacity !== backgroundScrollingOpacity) updateWormholeOpacity(backgroundScrollingOpacity);
  if (timer !== null) {
    clearTimeout(timer);
  }
  timer = setTimeout(function () {
    backgroundStationaryScrollingOpacity = backgroundScrollingOpacity
    let backgroundOpacityInterval = setInterval(() => {
      backgroundStationaryScrollingOpacity -= 0.002
      updateWormholeOpacity(backgroundStationaryScrollingOpacity);
      if (backgroundStationaryScrollingOpacity < 0) {
        clearInterval(backgroundOpacityInterval);
      }
    }, starDuration);
  }, starDelay);
}, false);

camera.position.x = wormholeSpline.points[0].x;
camera.position.z = wormholeSpline.points[0].z;
camera.lookAt(wormhole.geometry.parameters.path.getPointAt(0.00001));
//maintainPanel(0, camera)

function goToLoans2() {
  window.scrollTo(window.scrollX, 1.3 * window.innerHeight);
}

function goToLoans3() {
  window.scrollTo(window.scrollX, 2.2 * window.innerHeight);
}

function goToLoans4() {
  window.scrollTo(window.scrollX, 3.2 * window.innerHeight);
}

// function showFooter(percent, percentStepOffset, cutoff) {
//   let opacity = (Math.exp(percent - percentStepOffset)) / 50
//   const footer = document.getElementById('footer-links')
//   const scroll = document.getElementById('scroll-icon')
//   if (opacity < 1.0 && percent >= cutoff) {
//     footer.style.opacity = opacity
//   } else {
//     footer.style.opacity = 1;
//   }
//   scroll.style.opacity = 0
// }

// function debounce(func, timeout = 300) {
//   let debounceTimer;
//   return (...args) => {
//     clearTimeout(debounceTimer);
//     debounceTimer = setTimeout(() => { func.apply(this, args); }, timeout);
//   };
// }

// function showScroll() {
//   const scroll = document.getElementById('scroll-icon')
//   gsap.fromTo(scroll, { opacity: 0 }, { opacity: 1, duration: 0.5 })
// }

// function hideFooter(percent, percentStepOffset, cutoff) {
//   let opacity = 1 - (Math.exp(percent - percentStepOffset)) / 10
//   const footer = document.getElementById('footer-links')
//   // const scroll = document.getElementById('scroll-icon')
//   if (opacity > 0 && percent >= cutoff) {
//     footer.style.opacity = opacity;
//     // scroll.style.opacity = 1 - opacity
//   } else {
//     footer.style.opacity = 0;
//     // scroll.style.opacity = 1;
//   }
// }
let scrolling = false;
let hideScroll;
function scrollAction(scrollRatio) {
  scrollDelta = scrollRatio - lastScrollPos;

  var panelLabel = document.getElementById('panel_label');
  function changeLabel(newLabel) {
    panelLabel.innerHTML = newLabel;
  }

  if ((scrollRatio < 0.04 && scrollDelta > 0) || scrollRatio >= 0.04) {
    galaxy2.offset.x += backgroundScrollingSpeed;

    //TODO: comment this out and add the point light to the camera rather than the scene. same as spotlights
    //movePointLight()
    //console.log('delta', scrollDelta)
    // scrollParams.percentDirect =
    //   scrollContainer.scrollTop /
    //   (scrollContainer.scrollHeight - scrollContainer.clientHeight);

    // scrollParams.percentSmooth = scrollParams.percentDirect;
    const pathPos = wormhole.geometry.parameters.path.getPointAt(scrollRatio)
    const percentStep = Math.round((scrollRatio).toFixed(2) * 100);
    const percentStepDecimal = (scrollRatio) * 100;
    if (scrollRatio < 0.968) {

      camera.position.copy(pathPos)
      //let cameraY = Math.sin(scrollRatio*25)
      //console.log(cameraY);
      //camera.position.y = cameraY;
      camera.lookAt(wormhole.geometry.parameters.path.getPointAt(scrollRatio + 0.001))
    }
    //console.log(' PosX: ' +camera.position.x+ ', PosY: ' +camera.position.y+ ', PosZ: ' +camera.position.z)

    //---------------------------- step 1 ----------------------------------------
    //panel 1 - START
    // if (percentStep == 0 && percentStep < 3) {
    //   fadeInPanel(0, scrollDelta);
    //   navigationItem.forEach((el) => el.classList.remove('active'))
    //   navigationItem[0].classList.add('active');
    // } else if (percentStep >= 3 && percentStep < 7) {
    //   maintainPanel(0);
    // 100 / 7 =
    let sectionStepCount = 100 / 7
    if (percentStepDecimal >= 0 && percentStepDecimal < 4) {
      navigationItem.forEach((el) => el.classList.remove('active'))
      //navigationItem[0].style.height = `${100}%`;
      navigationItem[0].classList.add('active');
      fadeInPanel(0, 0.1, camera, wormhole);
      navigationItem[0].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 0.04) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 4 && percentStepDecimal < 5) {
      const body = document.body
      if (!body.classList.contains('show-scroll'))
        setTimeout(function () {
          body.classList.add('show-scroll');
        }, 3000);
      maintainPanel(0, camera)
      navigationItem[0].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 0.052) * sectionStepCount}%`;//${(percentStep < 0 ? 0 : percentStep) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 5 && percentStepDecimal < 8) {
      fadeOutPanel(0, scrollDelta, camera);
      navigationItem.forEach((el) => el.classList.remove('active'))
      navigationItem[0].classList.add('active');
      navigationItem[0].querySelector('.bar .filler').style.height = `${percentStepDecimal * sectionStepCount}%`;
    }

    //---------------------------- step 2 ----------------------------------------
    // Loans 1
    else if (percentStepDecimal >= 8 && percentStepDecimal < 10) {
      // document.getElementById("label_2").style.opacity = '1';
      sectionStepCount = 100 / 37 //45- 8, FINISH - START
      fadeInPanel(1, scrollDelta, camera, wormhole);
      navigationItem.forEach((el) => el.classList.remove('active'))
      navigationItem[1].classList.add('active');
      //navigationItem[1].style.height = `${100}%`;
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 10 && percentStepDecimal < 13) {
      sectionStepCount = 100 / 37 //45- 8, FINISH - START
      maintainPanel(1, camera);
      navigationItem[1].classList.remove('section-4-active');
      navigationItem[1].classList.remove('section-3-active');
      navigationItem[1].classList.remove('section-2-active');
      navigationItem.forEach((el) => el.classList.remove('active'))
      navigationItem[1].classList.add('active');
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 13 && percentStepDecimal < 16) {
      sectionStepCount = 100 / 37 //45- 8, FINISH - START
      navigationItem[1].classList.remove('section-2-active');
      fadeOutPanel(1, scrollDelta, camera);
      navigationItem[1].classList.add('active');
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
    }

    // Loans 2
    else if (percentStepDecimal >= 16 && percentStepDecimal < 18) {
      sectionStepCount = 100 / 37 //45- 8, FINISH - START
      fadeInPanel(2, scrollDelta, camera, wormhole);
      navigationItem[1].classList.add('section-2-active');
      navigationItem[1].classList.add('active');
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 18 && percentStepDecimal < 21) {
      sectionStepCount = 100 / 37 //45- 8, FINISH - START
      maintainPanel(2, camera);
      navigationItem[1].classList.add('active');
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 21 && percentStepDecimal < 22) {
      sectionStepCount = 100 / 37 //45- 8, FINISH - START
      navigationItem[1].classList.remove('section-3-active');
      fadeOutPanel(2, scrollDelta, camera);
      navigationItem[1].classList.add('active');
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
    }

    // Loans 3
    else if (percentStepDecimal >= 22 && percentStepDecimal < 24) {
      sectionStepCount = 100 / 37 //45- 8, FINISH - START
      fadeInPanel(3, scrollDelta, camera, wormhole);
      navigationItem[1].classList.add('section-3-active');
      navigationItem[1].classList.add('active');
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 24 && percentStepDecimal < 27) {
      sectionStepCount = 100 / 37 //45- 8, FINISH - START
      maintainPanel(3, camera);
      navigationItem[1].classList.add('active');
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 28 && percentStepDecimal < 29) {
      sectionStepCount = 100 / 37 //45- 8, FINISH - START
      navigationItem[1].classList.remove('section-4-active');
      fadeOutPanel(3, scrollDelta, camera);
      navigationItem[1].classList.add('active');
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
    }

    // Loans 4
    else if (percentStepDecimal >= 29 && percentStepDecimal < 31) {
      sectionStepCount = 100 / 37 //45- 8, FINISH - START
      fadeInPanel(4, scrollDelta, camera, wormhole);
      navigationItem[1].classList.add('section-4-active');
      navigationItem[1].classList.add('active');
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 31 && percentStepDecimal < 34) {
      sectionStepCount = 100 / 37 //45- 8, FINISH - START
      maintainPanel(4, camera);
      navigationItem[1].classList.add('active');
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 34 && percentStepDecimal < 35) { // 38
      sectionStepCount = 100 / 37 //45- 8, FINISH - START
      // navigationItem.forEach((el) => el.classList.remove('active'))
      navigationItem[1].classList.add('active');
      fadeOutPanel(4, scrollDelta, camera);
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 35 && percentStepDecimal < 45) {
      sectionStepCount = 100 / 37
      navigationItem.forEach((el) => el.classList.remove('active'))
      navigationItem[1].classList.add('active');
      navigationItem[1].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 8) * sectionStepCount}%`;
      hideAllPanels()
    }

    //---------------------------- step 3 ----------------------------------------

    //panel 6 - About
    else if (percentStepDecimal >= 45 && percentStepDecimal < 48) {
      sectionStepCount = 100 / 15 //60- 45, FINISH - START
      fadeInPanel(5, scrollDelta, camera, wormhole);
      navigationItem.forEach((el) => el.classList.remove('active'))
      navigationItem[2].classList.add('active');
      navigationItem[2].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 45) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 48 && percentStepDecimal < 50) {
      sectionStepCount = 100 / 15 //60- 45, FINISH - START
      maintainPanel(5, camera);
      navigationItem[2].classList.remove('section-2-active');
      navigationItem.forEach((el) => el.classList.remove('active'))
      navigationItem[2].classList.add('active');
      navigationItem[2].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 45) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 50 && percentStepDecimal < 51) {
      sectionStepCount = 100 / 15 //60- 45, FINISH - START
      navigationItem[2].classList.remove('section-2-active');
      fadeOutPanel(5, scrollDelta, camera);
      navigationItem[2].classList.add('active');
      navigationItem[2].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 45) * sectionStepCount}%`;
    }

    //panel 7 - Team
    else if (percentStepDecimal >= 51 && percentStepDecimal < 54) {
      sectionStepCount = 100 / 15 //60- 45, FINISH - START
      fadeInPanel(6, scrollDelta, camera, wormhole);
      navigationItem[2].classList.add('section-2-active');
      navigationItem[2].classList.add('active');
      navigationItem[2].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 45) * sectionStepCount}%`;

    } else if (percentStepDecimal >= 54 && percentStepDecimal < 57) {
      sectionStepCount = 100 / 15 //60- 45, FINISH - START
      maintainPanel(6, camera);
      navigationItem[2].classList.add('active');
      navigationItem[2].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 45) * sectionStepCount}%`;

    } else if (percentStepDecimal >= 57 && percentStepDecimal < 60) {
      sectionStepCount = 100 / 15 //60- 45, FINISH - START
      navigationItem.forEach((el) => el.classList.remove('active'))
      fadeOutPanel(6, scrollDelta, camera);
      navigationItem[2].classList.add('active');
      navigationItem[2].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 45) * sectionStepCount}%`;
    }

    //---------------------------- step 4 ----------------------------------------
    //panel 8 - Case Studies
    else if (percentStepDecimal >= 60 && percentStepDecimal < 63) {
      sectionStepCount = 100 / 25 //85- 60, FINISH - START
      fadeInPanel(7, scrollDelta, camera, wormhole);
      navigationItem.forEach((el) => el.classList.remove('active'))
      navigationItem[3].classList.add('active');
      navigationItem[3].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 60) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 63 && percentStepDecimal < 66) {
      sectionStepCount = 100 / 25 //85- 60, FINISH - START
      maintainPanel(7, camera);
      navigationItem[3].classList.remove('section-2-active');
      navigationItem.forEach((el) => el.classList.remove('active'))
      navigationItem[3].classList.add('active');
      navigationItem[3].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 60) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 66 && percentStepDecimal < 68) {
      sectionStepCount = 100 / 25 //85- 60, FINISH - START
      navigationItem[3].classList.remove('section-2-active');
      fadeOutPanel(7, scrollDelta, camera);
      navigationItem[3].classList.add('active');
      navigationItem[3].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 60) * sectionStepCount}%`;
    }

    //panel 9 - Testimonials
    else if (percentStepDecimal >= 68 && percentStepDecimal < 71) {
      sectionStepCount = 100 / 25 //85- 60, FINISH - START
      fadeInPanel(8, scrollDelta, camera, wormhole);
      navigationItem[3].classList.add('section-2-active');
      navigationItem[3].classList.add('active');
      navigationItem[3].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 60) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 71 && percentStepDecimal < 74) {
      sectionStepCount = 100 / 25 //85- 60, FINISH - START
      maintainPanel(8, camera);
      navigationItem[3].classList.add('active');
      navigationItem[3].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 60) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 74 && percentStepDecimal < 85) {
      sectionStepCount = 100 / 25 //85- 60, FINISH - START
      navigationItem.forEach((el) => el.classList.remove('active'))
      fadeOutPanel(8, scrollDelta, camera);
      navigationItem[3].classList.add('active');
      navigationItem[3].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 60) * sectionStepCount}%`;
    }

    //---------------------------- step 5 ----------------------------------------
    //step 5 section 2
    //panel 10 - Contact
    else if (percentStepDecimal >= 86 && percentStepDecimal < 89) {
      sectionStepCount = 100 / 14 //100 - 86, FINISH - START
      fadeInPanel(9, scrollDelta, camera, wormhole);
      navigationItem.forEach((el) => el.classList.remove('active'))
      navigationItem[4].classList.add('active');
      navigationItem[4].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 86) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 89 && percentStepDecimal < 92) {
      sectionStepCount = 100 / 14 //100 - 86, FINISH - START
      maintainPanel(9, camera);
      navigationItem[4].classList.remove('section-2-active');
      navigationItem.forEach((el) => el.classList.remove('active'))
      navigationItem[4].classList.add('active');
      navigationItem[4].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 86) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 92 && percentStepDecimal < 94) {
      sectionStepCount = 100 / 14 //100 - 86, FINISH - START
      navigationItem[4].classList.remove('section-2-active');
      fadeOutPanel(9, scrollDelta, camera);
      navigationItem[4].classList.add('active');
      navigationItem[4].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 86) * sectionStepCount}%`;
    }

    //step 5 section 2
    //panel 11 - Footer
    else if (percentStepDecimal >= 94 && percentStepDecimal < 97) {
      sectionStepCount = 100 / 14 //100 - 86, FINISH - START
      fadeInPanel(10, scrollDelta, camera, wormhole);
      navigationItem[4].classList.add('section-2-active');
      navigationItem[4].classList.add('active');
      navigationItem[4].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 86) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 97 && percentStepDecimal < 100) {
      sectionStepCount = 100 / 14 //100 - 86, FINISH - START
      maintainPanel(10, camera);
      navigationItem[4].classList.add('active');
      navigationItem[4].querySelector('.bar .filler').style.height = `${(percentStepDecimal - 86) * sectionStepCount}%`;
    } else if (percentStepDecimal >= 100) {
    }

    // footer opacity
    clearTimeout(hideScroll)
    if (percentStepDecimal < 91) {
      const footer = document.getElementById('footer-links');
      if (footer.style.opacity === "1") footer.style.opacity = 0
      const scroll = document.getElementById('scroll-icon')
      if (!scrolling && percentStepDecimal >= 5) {
        scroll.style.opacity = 0
        scrolling = true
      }
      hideScroll = setTimeout(() => {
        scroll.style.opacity = 1
        scrolling = false
      }, 500)
    } else if (percentStepDecimal >= 91) {
      const footer = document.getElementById('footer-links');
      footer.style.opacity = 1
    }



    // panel labels
    if (percentStepDecimal >= 0 && percentStepDecimal < 8) {
      changeLabel('');
    }
    //---------------------------- step 2 ----------------------------------------
    // Loans 1
    if (percentStepDecimal >= 8 && percentStepDecimal < 45) {
      changeLabel('Loans');
    }

    //---------------------------- step 3 ----------------------------------------

    //panel 6 - About
    else if (percentStepDecimal >= 45 && percentStepDecimal < 48) {
      changeLabel('About');
    }

    //---------------------------- step 4 ----------------------------------------
    //panel 8 - Case Studies
    else if (percentStepDecimal >= 60 && percentStepDecimal < 71) {
      changeLabel('Success Stories');

    }

    //---------------------------- step 5 ----------------------------------------
    //step 5 section 2
    //panel 10 - Contact
    else if (percentStepDecimal >= 86 && percentStepDecimal < 100) {
      changeLabel('Contact Us');
    }

    // panel labels end



    if (percentStepDecimal >= 0 && percentStepDecimal < 12) {
      maintainTube(tubesL, 0);
    } else if (percentStepDecimal >= 12 && percentStepDecimal < 32) {
      fadeInTube(tubesL, percentStepDecimal, 11, 400.0);
      fadeOutTube(tubesR, percentStepDecimal, 11, 140.0);
    } else if (percentStepDecimal >= 32 && percentStepDecimal < 54) {
      fadeInTube(tubesR, percentStepDecimal, 31, 1000.0);
      fadeOutTube(tubesL, percentStepDecimal, 31, 500.0);
    } else if (percentStepDecimal >= 54 && percentStepDecimal < 73) {
      fadeInTube(tubesL, percentStepDecimal, 53, 800.0);
      fadeOutTube(tubesR, percentStepDecimal, 53, 100.0)
    } else if (percentStepDecimal >= 73 && percentStepDecimal < 79) {
      fadeInTube(tubesR, percentStepDecimal, 72, 500.0);
    }
    // else if (percentStepDecimal >= 79) {
    //   fadeInTube(tubesR, percentStepDecimal, 78, 200.0);
    // }
    // else if (percentStepDecimal >= 73 && percentStepDecimal < 79) {
    //   fadeInTube(tubesR, percentStepDecimal, 72, 500.0);
    //   fadeOutTube(tubesL, percentStepDecimal, 72, 500.0)
    // } else if (percentStepDecimal >= 79 && percentStepDecimal < 99.99999) {
    //   fadeInTube(tubesL, percentStepDecimal, 78, 400.0);
    // } else if (percentStepDecimal >= 99) {
    //   //scrollContainer.scrollTop = 0;
    // }

    if (percentStepDecimal >= 81) {
      hideTop(tubesL, percentStepDecimal, 80, 400)
      hideTop(tubesR, percentStepDecimal, 80, 400)
    } else if (percentStepDecimal >= 80) {
      showTop(tubesL)
      showTop(tubesR)
    }

    const farPlaneEnd = 70
    const farPlaneStart = 200

    const farPlanePercentageTransitionStart = 77
    const farPlanePercentageTransitionEnd = 87

    if (percentStepDecimal > farPlanePercentageTransitionEnd) {
      if (camera.far != farPlaneEnd) {
        camera.far = farPlaneEnd
        camera.updateProjectionMatrix()
      }
    } else if (percentStepDecimal > farPlanePercentageTransitionStart && percentStepDecimal <= farPlanePercentageTransitionEnd) {
      camera.far = farPlaneStart - (percentStepDecimal - farPlanePercentageTransitionStart) * ((farPlaneStart - farPlaneEnd) / 10.0)
      camera.updateProjectionMatrix()
    } else {
      if (camera.far != farPlaneStart) {
        camera.far = farPlaneStart
        camera.updateProjectionMatrix()
      }
    }

    //console.log(camera.far)

    const percentCss = `${percentStep >= 0 ? percentStep : 0}%`;
    // document.getElementById("percentage").innerHTML = percentCss;
    document.getElementById("percent_bar").style.height = percentCss;
    lastScrollPos = scrollRatio;
  } else if (scrollRatio < 0.04 && scrollDelta < 0) {
    window.scrollTo(0, 0.76 * window.innerHeight)
  }
}

function scrollLock(scroller) {
  const scrollRatio = scroller.scrollTrigger.progress
  const percentStep = Math.round(scrollRatio.toFixed(2) * 100)
  //---------------------------- step 1 ----------------------------------------
  //panel 1
  if (percentStep >= 7 && percentStep < 10) {
    window.scrollTo(window.scrollX, 0.03 * 10 * window.innerHeight);
  }

  //---------------------------- step 2 ----------------------------------------
  //section 1
  //panel 2
  else if (percentStep >= 10 && percentStep < 13) {
    window.scrollTo(window.scrollX, 0.13 * 10 * window.innerHeight);
  } else if (percentStep >= 17 && percentStep < 20) {
    window.scrollTo(window.scrollX, 0.13 * 10 * window.innerHeight);
  }

  //step 2 section 2
  //panel 3
  else if (percentStep >= 20 && percentStep < 23) {
    window.scrollTo(window.scrollX, 0.23 * 10 * window.innerHeight);
  } else if (percentStep >= 27 && percentStep < 30) {
    window.scrollTo(window.scrollX, 0.23 * 10 * window.innerHeight);
  }


  //---------------------------- step 3 ----------------------------------------
  //section 1
  //panel 4
  else if (percentStep >= 30 && percentStep < 33) {
    window.scrollTo(window.scrollX, 0.33 * 10 * window.innerHeight);
  } else if (percentStep >= 37 && percentStep < 40) {
    window.scrollTo(window.scrollX, 0.33 * 10 * window.innerHeight);
  }

  //step 3 section 2
  //panel 5
  else if (percentStep >= 42 && percentStep < 45) {
    window.scrollTo(window.scrollX, 0.45 * 10 * window.innerHeight);
  } else if (percentStep >= 52 && percentStep < 55) {
    window.scrollTo(window.scrollX, 0.45 * 10 * window.innerHeight);
  }

  //---------------------------- step 4 ----------------------------------------
  //section 1
  //panel 6
  else if (percentStep >= 55 && percentStep < 58) {
    window.scrollTo(window.scrollX, 0.58 * 10 * window.innerHeight);
  } else if (percentStep >= 63 && percentStep < 65) {
    window.scrollTo(window.scrollX, 0.58 * 10 * window.innerHeight);
  }

  //step 4 section 2
  //panel 7
  else if (percentStep >= 65 && percentStep < 68) {
    window.scrollTo(window.scrollX, 0.68 * 10 * window.innerHeight);
  } else if (percentStep >= 77 && percentStep < 80) {
    window.scrollTo(window.scrollX, 0.68 * 10 * window.innerHeight);
  }

  //---------------------------- step 5 ----------------------------------------
  //section 1
  //panel 8
  else if (percentStep >= 80 && percentStep < 83) {
    window.scrollTo(window.scrollX, 0.83 * 10 * window.innerHeight);
  } else if (percentStep >= 87 && percentStep < 90) {
    window.scrollTo(window.scrollX, 0.83 * 10 * window.innerHeight);
  }

  //step 5 section 2
  //panel 9
  else if (percentStep >= 90 && percentStep < 93) {
    window.scrollTo(window.scrollX, 0.93 * 10 * window.innerHeight);
  } else if (percentStep >= 97 && percentStep < 100) {
    window.scrollTo(window.scrollX, 0.93 * 10 * window.innerHeight);
  }
}
/*

  _
 | |
 | |___      _____  ___ _ __
 | __\ \ /\ / / _ \/ _ \ '_ \
 | |_ \ V  V /  __/  __/ | | |
  \__| \_/\_/ \___|\___|_| |_|

*/
//setTweenParams(wormhole, scrollParams, gsap, camera);
//document.addEventListener('mousemove', onMouseMoveTween, false)
window.scrollTo(0, 0)

function hideToolKit() {
  scene.background = null
  navigationItem.forEach((panel) => {
    panel.style.display = 'none';
  });
  stats.domElement.style.display = 'none'
  document.getElementById("percentage").style.display = 'none';
  document.getElementById("percent_bar").style.display = 'none';
  document.getElementById("guide").style.display = 'none';
}

function showToolKit() {
  scene.background = backgroundTexture
  // scene.background = null
  navigationItem.forEach((panel) => {
    panel.style.display = 'block';
  });
  stats.domElement.style.display = 'block'
  document.getElementById("percentage").style.display = 'block';
  document.getElementById("percent_bar").style.display = 'block';
  document.getElementById("guide").style.display = 'block';
}

function changeControls() {
  if (backgroundOrbitControls) {
    let navigation = document.getElementById('navigation')
    navigation.style.display = "none"
    container.style.zIndex = 999
    camera.near = 0.00000000000001
    camera.far = 10000000000000000
    camera.updateProjectionMatrix()
    controls.listenToKeyEvents(window)
    console.log(controls)
    controls.target = wormhole.geometry.parameters.path.getPointAt(lastScrollPos + 0.001)
    controls.update()
    controls.enabled = true
  } else {
    let navigation = document.getElementById('navigation')
    navigation.style.display = "block"
    container.style.zIndex = 1
    camera.near = 0.1
    camera.far = 200
    camera.updateProjectionMatrix()
    controls._domElementKeyEvents = null
    controls.enabled = false
    camera.position.copy(wormhole.geometry.parameters.path.getPointAt(lastScrollPos))
    camera.lookAt(wormhole.geometry.parameters.path.getPointAt(lastScrollPos + 0.001))
  }
}


const $cursor = document.querySelector('.cursor-dot-outline');

document.body.addEventListener('mousemove', (e) => {
  gsap.to($cursor, {
    duration: 0.5,
    delay: 0,
    x: e.clientX + 100,
    y: e.clientY + 100,
    ease: "back.out(2)"
  });
});
const listenIframes = () => {
  setTimeout(() => {
    const iframes = document.querySelectorAll('iframe')
    if (iframes.length > 0) {
      iframes.forEach(iframe => {
        iframe.contentDocument.addEventListener('mousemove', (event) => {
          const iframeRect = iframe.getBoundingClientRect();
          const clientYMain = event.clientY + iframeRect.top;
          const clientXMain = event.clientX + iframeRect.left;
          gsap.to($cursor, {
            duration: 0.5,
            delay: 0,
            x: clientXMain + 0,
            y: clientYMain + 0,
            ease: "back.out(2)"
          });
        })
      })
    } else {
      listenIframes()
    }
  }, 1000)
}
listenIframes()