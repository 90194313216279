import { AdditiveBlending, BackSide, Vector2, EllipseCurve, MirroredRepeatWrapping, RepeatWrapping, DoubleSide, MeshBasicMaterial, Mesh, TextureLoader, Vector3, CatmullRomCurve3, TubeGeometry } from "three"
import { createMultiMaterialObject } from 'three/examples/jsm/utils/SceneUtils.js'
import { gsap } from 'gsap'

function generatePath() {
  const arc = new EllipseCurve(
    0, 0,            // ax, aY
    330, 330,           // xRadius, yRadius
    (-1 / 4) * Math.PI, (3 / 2) * Math.PI,  // aStartAngle, aEndAngle
    false,            // aClockwise
    0                 // aRotation
  )
  const arc1 = new EllipseCurve(
    0, -825,
    495, 495,
    (1 / 2) * Math.PI, (5 / 5) * Math.PI,
    true,
    0
  )
  const arc2 = new EllipseCurve(
    -990, -825,
    495, 495,
    // (0 / 5) * Math.PI, (23 / 16) * Math.PI,
    0, (3 / 2) * Math.PI,
    false,
    0
  )
  const arc3 = new EllipseCurve(
    -990, -1815,
    495, 495,
    // (7 / 16) * Math.PI, (17 / 16) * Math.PI,
    (1 / 2) * Math.PI, (5 / 4) * Math.PI,
    true,
    0
  )
  const arc4 = new EllipseCurve(
    -1980, -1815,
    495, 495,
    (0) * Math.PI, (3 / 8) * Math.PI,
    false,
    0
  )
  // let points = [...arc.getPoints(200), ...arc1.getPoints(200), ...arc2.getPoints(200), ...arc3.getPoints(200), ...arc4.getPoints(100)]
  let points = [...arc.getPoints(1000), ...arc1.getPoints(1000), ...arc2.getPoints(1000), ...arc3.getPoints(1000)]
  // points = [...points, ...[...new Array(1600).keys()].map(o => new Vector2(-0.9206615454515065, 0.39036177928537474).multiplyScalar(o + 1).add(points[points.length - 1]))]
  points = [...points, ...[...new Array(2400).keys()].map(o => (new Vector2(0, 0).add(points[points.length - 1]).sub(points[points.length - 2])).normalize().multiplyScalar(o + 1).add(points[points.length - 1]))]
  return points
}

export const wormholePath = generatePath()

function generateWormholeSpline() {
  const points = wormholePath
  return new CatmullRomCurve3(points.map((o, i) => {
    return new Vector3(o.x, 0, o.y)
  }), false, 'chordal')
}

export const wormholeSpline = generateWormholeSpline()

const geometry = new TubeGeometry(wormholeSpline, 300, 40, 36)

export const galaxy2 = new TextureLoader().load(require('../textures/background/stars3.png'));
galaxy2.wrapS = RepeatWrapping
galaxy2.wrapT = RepeatWrapping
galaxy2.repeat.set(3, 5)
const auraSpeederMaterial = new MeshBasicMaterial({
  map: galaxy2,
  transparent: true,
  opacity: 0.1,
  blending: AdditiveBlending,
  side: DoubleSide,
})

export const wormhole = new Mesh(geometry, auraSpeederMaterial);
// export const wormholeTimeline = gsap.timeline()

export function updateWormholeOpacity(opacity) {
  auraSpeederMaterial.opacity = opacity;
}
